import { Injectable } from '@angular/core';
import { Breadcrumb } from '@core/top-navbar/breadcrumb/breadcrumb.component';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SubscriptionVariablesService {
  getSubTab: Observable<number>;
  changeBreadcrumb: Observable<Breadcrumb>;
  breadcrumbValues: Breadcrumb;

  private readonly breadcrumb = new Subject<Breadcrumb>();
  private readonly controlSubTabS = new Subject<number>();

  constructor() {
    this.changeBreadcrumb = this.breadcrumb.asObservable();
    this.getSubTab = this.controlSubTabS.asObservable();
  }

  controlSubTab(subTab: number): void {
    this.controlSubTabS.next(subTab);
  }

  setBreadcrumb(breadcrumb: Breadcrumb): void {
    this.breadcrumbValues = breadcrumb;
    this.breadcrumb.next(breadcrumb);
  }
}
