import { NgFor, NgIf } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { InfoKey } from '@shared/constants/constants';

import { EntityName, RiskCaseRcsaType } from '@shared/models';

import { informationLists, informationListsIssue } from '../../constants/user-guide-data';

type IconSize = 'small' | 'medium' | 'large';
type IconScale = 1 | 1.5 | 2;

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatDialogModule, MatExpansionModule, MatButtonModule, NgIf, NgFor, MatTooltip],
})
export class UserGuideComponent {
  @Input() entityName: EntityName;
  @Input() infoKey: InfoKey;
  @Input() infoType: RiskCaseRcsaType;
  @ViewChild('userGuideDialog') userGuideDialog!: TemplateRef<HTMLDivElement>;
  panelOpenState = false;
  dialogRef: MatDialogRef<HTMLDivElement>;
  informationLists: Array<{ title: string; information: string; infoKey: InfoKey }>;
  infoTitle: string = 'Risk and Control Self-Assessment (RCSA)';
  iconScale: IconScale = 1;

  private readonly iconSizeToIconScale: Record<IconSize, IconScale> = {
    small: 1,
    medium: 1.5,
    large: 2,
  };

  constructor(public dialog: MatDialog) {}

  @Input() set iconSize(iconSize: IconSize) {
    this.iconScale = this.iconSizeToIconScale[iconSize];
  }

  openDialog(): void {
    this.setData();
    this.dialogRef = this.dialog.open<HTMLDivElement>(this.userGuideDialog, {
      width: '1308px',
      autoFocus: false,
      disableClose: true,
    });
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  setInfoData(infoType: RiskCaseRcsaType): void {
    this.infoType = infoType;
    this.openDialog();
  }

  private setData(): void {
    if (this.infoType === 'RCSA') {
      this.informationLists = undefined;
      this.infoTitle = 'Risk and Control Self-Assessment (RCSA)';
    }
    if (this.infoType === 'Issue') {
      this.informationLists = informationListsIssue;
      this.infoTitle = 'Issue Management';
    }
    if (this.infoType === 'Event') {
      this.informationLists = informationLists;
      this.infoTitle = 'Event Reporting';
    }
    if (this.infoType === 'Event_Issue') {
      this.informationLists = informationLists;
      this.infoTitle = 'Event and Issue';
    }
  }
}
