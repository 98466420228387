import { startOfQuarter, addMonths, getYear, getMonth } from 'date-fns';

export function getRcsaQuarter(date = new Date()): { startDate: Date; endDate: Date } {
  const isFirstQuarter2025 = getYear(date) === 2025 && getMonth(date) < 3;

  let startDate = startOfQuarter(date);
  let endDate: Date;

  startDate = addMonths(startDate, 1);
  endDate = addMonths(startDate, 3);
  endDate.setDate(endDate.getDate() - 1);

  if (isFirstQuarter2025) {
    startDate = addMonths(startDate, -1);
  }

  return { startDate, endDate };
}
