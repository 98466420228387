import { Adapter } from '@shared/adapters/adapter';
import { RiskCaseView } from 'oneorm-api-http-client';

import { RiskCaseType, RiskCaseViewFE } from '@shared/models';
import { isNil } from '@shared/utils';

export abstract class RiskCasesAdapter extends Adapter<RiskCaseViewFE[], RiskCaseView[]> {
  static fromDto(dto: RiskCaseView[]): RiskCaseViewFE[] {
    if (isNil(dto)) {
      return [];
    }

    return dto.map(
      riskCase =>
        ({
          ...riskCase,
          class_name: riskCase.class_name as RiskCaseType,
          actionOwner: {
            user_name: riskCase.action_item_assignee,
            user_id: riskCase.action_item_assignee_id,
            user_status: '',
          },
          actionDelegate: {
            user_name: riskCase.action_item_delegate,
            user_id: riskCase.action_item_delegate_id,
            user_status: '',
          },
          creator: {
            user_name: riskCase.creator_name,
            user_id: riskCase.creator_id,
            user_status: riskCase.creator_status,
          },
          owner: {
            user_name: riskCase.owner_name,
            user_id: riskCase.owner_id,
            user_status: riskCase.owner_status,
          },
          delegate: {
            user_name: riskCase.delegate_name,
            user_id: riskCase.delegate_id,
            user_status: riskCase.delegate_status,
          },
          rcsa_owner: {
            user_name: riskCase.rcsa_owner_name,
            user_id: riskCase.rcsa_owner_id,
            user_status: riskCase.rcsa_owner_status,
          },
          risk_manager: {
            user_name: riskCase.risk_manager_name,
            user_id: riskCase.risk_manager_id,
            user_status: riskCase.risk_manager_status,
          },
          governanceCoordinator: {
            user_name: riskCase.governance_coordinator,
            user_id: riskCase.governance_coordinator_id,
            user_status: '',
          },
          identifyingLod: {
            name: riskCase.lod,
            id: riskCase.lod_id,
          },
          impactRating: {
            name: riskCase.impact_rating,
            id: riskCase.impact_rating_id,
          },
          status: {
            name: riskCase.status,
            id: riskCase.status_id,
          },
        }) as RiskCaseViewFE
    );
  }
}
